import PaymentForm from './PaymentForm';

const Register = () => {
  return (
    <>
      {/* <div className="flex flex-col mt-10 justify-center bg-white p-4">
        <div className="p-8 mt-30"> */}
            <PaymentForm/>
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Register;
