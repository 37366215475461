
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import axios from 'axios';
import apiClient from 'axiosInstance';
import SuccessModal from './RegisterSuccessModal';
import { FiEye, FiEyeOff } from "react-icons/fi";

const PaymentForm = () => {

    const [showModal, setShowModal] = useState(false);
    const history = useNavigate();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [isOtpVerified, setIsOtpVerified] = useState(false);

    const [company,setCompany] = useState('');
    const [gstNo,setGstNo] = useState('');

    const [generatedReferralCode, setGeneratedReferralCode] = useState('');


   
    const [showPassword, setShowPassword] = useState(false);


    const handleSendOtp = async () => {
        try {
            setLoading(true);
            // await apiClient.post(`/api/send-otp`, { contactNumber: mobile });
            setOtpSent(true);
            setError(null);
        } catch (error) {
            setError('Failed to send OTP. Please try again.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    const handleCheckUser = async () => {
        try {
            setLoading(true);
            const response = await apiClient.post('/api/isuser/exist', { contactNumber :mobile});
            if (response.data.status) {
                setError(response.data.message);
            } else {
                // await handleSendOtp();
                setOtpSent(true);
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleVerifyOtp = async () => {
        try {
            setLoading(true);
            // await apiClient.post(`/api/verify-otp`, { contactNumber: mobile, otp });
            setIsOtpVerified(true);
            setError(null);
        } catch (error) {
            setError('OTP verification failed. Please try again.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleRegister = async () => {
        try {
            setLoading(true);
            const registrationResponse = await apiClient.post(`/api/register`, {
                name,
                mobile,
                email,
                password,
                referralCode: referralCode,
                company,
                gstNo
            });
            setGeneratedReferralCode(registrationResponse.data.referralCode);
            if(registrationResponse.data.referralCode){
            setShowModal(true);
            }
            setError(registrationResponse.data.message)

        } catch (error) {
            // Check if the error is an Axios error and has a response
        if (axios.isAxiosError(error) && error.response) {
            // Set the error message from the backend response
            setError(error.response.data.message || 'An unknown error occurred.');
        } else {
            setError('Payment initiation failed. Please try again.');
        }
        console.error(error);
    } finally {
        setLoading(false);
    }
    };


    const handlePayment = async () => {
        const data = {
            name,
            amount: price,
            number: mobile,
            MUID: "MUID" + Date.now(),
            transactionId: 'T' + Date.now(),
        };

        try {
            setLoading(true);
            // After successful registration, proceed with payment
            const response = await apiClient.post(`/api/phone/pay`, data);
            window.location.href = response.data.redirectUrl; // Redirect to payment gateway
            setLoading(false)
        } catch (error) {
            setError('Payment initiation failed. Please try again.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(generatedReferralCode);
        alert('Referral code copied to clipboard!');
    };


   
  
  
    const handleCloseModal = () => {
      setShowModal(false);
      history('/login'); // Redirect to login page
    };

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 ">
//             <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full m-2">
//                 <h2 className="text-xl font-bold mb-4">Register Details</h2>
//                 <form onSubmit={(e) => e.preventDefault()}>
//                     <div className="mb-4">
//                         <label htmlFor="mobile" className="block text-gray-700">Mobile</label>
//                         <input
//                             type="tel"
//                             id="mobile"
//                             value={mobile}
//                             onChange={(e) => setMobile(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                             required
//                             pattern="^[6-9][0-9]{9}$"
//                             title="Mobile number must start with 6 and be 10 digits long"
//                         />
//                         {/* {!otpSent ? (
//                             <button
//                                 type="button"
//                                 onClick={handleCheckUser}
//                                 className={`bg-blue-500 text-white py-2 px-4 rounded-md mt-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Sending...' : 'Send OTP'}
//                             </button>
//                         ) : (
//                             <div className="mt-4">
//                                 <input
//                                     type="text"
//                                     placeholder="Enter OTP"
//                                     value={otp}
//                                     onChange={(e) => setOtp(e.target.value)}
//                                     className="block w-full px-3 py-2 border border-gray-300 rounded-md"
//                                 />
//                                 {!isOtpVerified && (
//                                     <button
//                                         type="button"
//                                         onClick={handleVerifyOtp}
//                                         className={`bg-blue-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                         disabled={loading}
//                                     >
//                                         {loading ? 'Verifying...' : 'Verify OTP'}
//                                     </button>
//                                 )}
//                             </div>
//                         )} */}

//                     </div>

//                     { (
//                         <>
                           
//                             <div className="mb-4">
//                                 <label htmlFor="name" className="block text-gray-700">Name</label>
//                                 <input
//                                     type="text"
//                                     id="name"
//                                     value={name}
//                                     onChange={(e) => setName(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label htmlFor="email" className="block text-gray-700">Email</label>
//                                 <input
//                                     type="email"
//                                     id="email"
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                     required
//                                 />
//                             </div>

//                             <div className="mb-4">
//     <label htmlFor="company" className="block text-gray-700">Company/Organization</label>
//     <input
//         type="text"
//         id="company"
//         value={company}
//         onChange={(e) => setCompany(e.target.value)}
//         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//     />
//     <p className="text-sm text-gray-500 mt-1">Optional</p> {/* Optional hint */}
// </div>

// <div className="mb-4">
//     <label htmlFor="gstNo" className="block text-gray-700">GST No:</label>
//     <input
//         type="text"
//         id="gstNo"
//         value={gstNo}
//         onChange={(e) => setGstNo(e.target.value)}
//         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//     />
//     <p className="text-sm text-gray-500 mt-1">Optional</p> {/* Optional hint */}
// </div>



//                             <div className="mb-4">
//       <label htmlFor="password" className="block text-gray-700">Password</label>
//       <div className="relative">
//         <input
//           type={showPassword ? "text" : "password"}
//           id="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//           required
//         />
//         <button
//           type="button"
//           onClick={() => setShowPassword(!showPassword)}
//           className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
//         >
//           {showPassword ? (
//             <FiEyeOff className="h-5 w-5" />
//           ) : (
//             <FiEye className="h-5 w-5" />
//           )}
//         </button>
//       </div>
//     </div>

//                             <div className="mb-4">
//                                 <label htmlFor="referralCode" className="block text-gray-700">Referral Code</label>
//                                 <input
//                                     type="text"
//                                     id="referralCode"
//                                     value={referralCode}
//                                     onChange={(e) => setReferralCode(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                             </div>
//                          <button
//                                 type="button"
//                                 onClick={handleRegister}
//                                 className={`bg-green-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Processing...' : 'Register'}
//                             </button>

//                             {generatedReferralCode && (
//                                 <>
//                                 <div className="mb-4">
//                                     <label className="block text-gray-700">Your Referral Code</label>
//                                     <div className="flex items-center">
//                                         <input
//                                             type="text"
//                                             value={generatedReferralCode}
//                                             readOnly
//                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
//                                         />
//                                         <button
//                                             type="button"
//                                             onClick={handleCopyReferralCode}
//                                             className="ml-2 bg-gray-200 text-gray-700 px-3 py-2 rounded-md"
//                                         >
//                                             Copy
//                                         </button>
//                                     </div>
//                                 </div>

//                                 {/* <button
//                                 type="button"
//                                 onClick={handlePayment}
//                                 className={`bg-green-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Processing...' : 'Pay Now'}
//                             </button> */}

//                                 </>
//                             )}

//                         </>
//                     )}

//                     {error && <p className="text-red-500 mb-4">{error}</p>}
                 
//                    {showModal && <SuccessModal onClose={handleCloseModal} />}
//                 </form>
//             </div>
//         </div>
//     );

return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full m-2 overflow-auto max-h-[80vh] mt-20">
            <h2 className="text-xl font-bold mb-4">Register Details</h2>
            <form onSubmit={(e) => e.preventDefault()} className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                    <label htmlFor="mobile" className="block text-gray-700">Mobile</label>
                    <input
                        type="tel"
                        id="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                        pattern="^[6-9][0-9]{9}$"
                        title="Mobile number must start with 6 and be 10 digits long"
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    />
                </div>

                {/* Optional Fields */}
                <div className="mb-4  ">
                    <div className="flex-1 flex items-center justify-between">
                        <label htmlFor="company" className="block text-gray-700">Company/Organization</label>
                        <p className="text-sm text-gray-500 ml-2">Optional</p>
                    </div>
                   
                    <input
                        type="text"
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>


                <div className="mb-4  ">
                <div className="flex-1 flex items-center justify-between">
                    <label htmlFor="gstNo" className="block text-gray-700">GST No:</label>
                    <p className="text-sm text-gray-500 mt-1">Optional</p>
                    </div>
                    <input
                        type="text"
                        id="gstNo"
                        value={gstNo}
                        onChange={(e) => setGstNo(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                   
                </div>

                {/* Password Field */}
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700">Password</label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            required
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                        >
                            {showPassword ? (
                                <FiEyeOff className="h-5 w-5" />
                            ) : (
                                <FiEye className="h-5 w-5" />
                            )}
                        </button>
                    </div>
                </div>

                {/* Referral Code Field */}
                <div className="mb-4">
                    <label htmlFor="referralCode" className="block text-gray-700">Referral Code</label>
                    <input
                        type="text"
                        id="referralCode"
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>

                {/* Register Button */}
                <button
                    type="button"
                    onClick={handleRegister}
                    className={`bg-green-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Register'}
                </button>

                {/* Generated Referral Code */}
                {generatedReferralCode && (
                    <>
                        <div className="mb-4">
                            <label className="block text-gray-700">Your Referral Code</label>
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    value={generatedReferralCode}
                                    readOnly
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                                />
                                <button
                                    type="button"
                                    onClick={handleCopyReferralCode}
                                    className="ml-2 bg-gray-200 text-gray-700 px-3 py-2 rounded-md"
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {error && <p className="text-red-500 mb-4">{error}</p>}
                {showModal && <SuccessModal onClose={handleCloseModal} />}
            </form>
        </div>
    </div>
);



};

export default PaymentForm;


// const PaymentForm = ({ onClose, price }) => {
//     const end_point = 'https://application.dosso21.com';
//     const [name, setName] = useState('');
//     const [mobile, setMobile] = useState('');
//     const [email, setEmail] = useState('');
//     const [referralCode, setReferralCode] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [otpSent, setOtpSent] = useState(false);
//     const [otp, setOtp] = useState('');
//     const [isOtpVerified, setIsOtpVerified] = useState(false);
//     const [generatedReferralCode, setGeneratedReferralCode] = useState('');

//     const handleSendOtp = async () => {
//         try {
//             setLoading(true);
//             await axios.post(`${end_point}/api/send-otp`, { contactNumber: mobile });
//             setOtpSent(true);
//             setError(null);
//         } catch (error) {
//             setError('Failed to send OTP. Please try again.');
//             console.error(error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleVerifyOtp = async () => {
//         try {
//             setLoading(true);
//             await axios.post(`${end_point}/api/verify-otp`, { contactNumber: mobile, otp });
//             setIsOtpVerified(true);
//             setError(null);
//         } catch (error) {
//             setError('OTP verification failed. Please try again.');
//             console.error(error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handlePayment = async () => {
//         const data = {
//             name,
//             amount: price,
//             number: mobile,
//             MUID: "MUID" + Date.now(),
//             transactionId: 'T' + Date.now(),
//         };

//         try {
//             setLoading(true);
//             const registrationResponse = await axios.post(`${end_point}/api/register/dosso/social/media`, {
//                 name,
//                 mobile,
//                 email,
//                 referralCode: referralCode || 'Admin',
//             });

//             setGeneratedReferralCode(registrationResponse.data.referralCode);

//             // After successful registration, proceed with payment
//             const response = await axios.post(`${end_point}/api/phone/pay`, data);
//             window.location.href = response.data.redirectUrl; // Redirect to payment gateway
//         } catch (error) {
//             setError('Payment initiation failed. Please try again.');
//             console.error(error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10">
//             <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
//                 <h2 className="text-xl font-bold mb-4">Payment Details</h2>
//                 <form onSubmit={(e) => e.preventDefault()}>
//                     <div className="mb-4">
//                         <label htmlFor="mobile" className="block text-gray-700">Mobile</label>
//                         <input
//                             type="tel"
//                             id="mobile"
//                             value={mobile}
//                             onChange={(e) => setMobile(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                             required
//                             pattern="^[6-9][0-9]{9}$"
//                             title="Mobile number must start with 6 and be 10 digits long"
//                         />
//                         {!otpSent ? (
//                             <button
//                                 type="button"
//                                 onClick={handleSendOtp}
//                                 className={`bg-blue-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Sending...' : 'Send OTP'}
//                             </button>
//                         ) : (
//                             <div className="mt-4">
//                                 <input
//                                     type="text"
//                                     placeholder="Enter OTP"
//                                     value={otp}
//                                     onChange={(e) => setOtp(e.target.value)}
//                                     className="block w-full px-3 py-2 border border-gray-300 rounded-md"
//                                 />
//                                 {!isOtpVerified && (
//                                     <button
//                                         type="button"
//                                         onClick={handleVerifyOtp}
//                                         className={`bg-blue-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                         disabled={loading}
//                                     >
//                                         {loading ? 'Verifying...' : 'Verify OTP'}
//                                     </button>
//                                 )}
//                             </div>
//                         )}
//                     </div>

//                     {isOtpVerified && (
//                         <>
//                             {generatedReferralCode && (
//                                 <div className="mb-4">
//                                     <label className="block text-gray-700">Referral Code</label>
//                                     <input
//                                         type="text"
//                                         value={generatedReferralCode}
//                                         readOnly
//                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
//                                     />
//                                 </div>
//                             )}
//                             <div className="mb-4">
//                                 <label htmlFor="name" className="block text-gray-700">Name</label>
//                                 <input
//                                     type="text"
//                                     id="name"
//                                     value={name}
//                                     onChange={(e) => setName(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label htmlFor="email" className="block text-gray-700">Email</label>
//                                 <input
//                                     type="email"
//                                     id="email"
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label htmlFor="referralCode" className="block text-gray-700">Referral Code</label>
//                                 <input
//                                     type="text"
//                                     id="referralCode"
//                                     value={referralCode}
//                                     onChange={(e) => setReferralCode(e.target.value)}
//                                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                                 />
//                             </div>
//                             <button
//                                 type="button"
//                                 onClick={handlePayment}
//                                 className={`bg-green-500 text-white py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={loading}
//                             >
//                                 {loading ? 'Processing...' : 'Pay Now'}
//                             </button>
//                         </>
//                     )}

//                     {error && <p className="text-red-500 mb-4">{error}</p>}
//                     <button
//                         type="button"
//                         onClick={onClose}
//                         className="bg-gray-500 text-white py-2 px-4 rounded-md mt-4"
//                     >
//                         Cancel
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default PaymentForm;
